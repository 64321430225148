import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, useStripe, useElements } from "@stripe/react-stripe-js";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Visa from "./assets/visa1.svg";
import MasterCard from "./assets/mastercard.svg";
import Amex from "./assets/amex.svg";
import CardExmaple from "./assets/cardexample.svg";
import Check from "./assets/Check.svg";
import BackArrow from "./assets/Back.svg";
import "./App.css";
import SuccessComponent from "./components/success";
import PreDonateComponent from "./components/preDonate";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import wwfIcon from "./assets/WWF_logo 1.svg";
import redCrossIcon from "./assets/redcross.svg";
import sosIcon from "./assets/sos.svg";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);
const baseUrl = process.env.REACT_APP_API_ENDPOINT;

let preDefindAmounts = [];

const CardForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [paymentMethods, setPaymentMethods] = useState([]);

  const filteredCards = paymentMethods.filter(
    (v, i, a) =>
      a.findIndex((t) => t.last4 === v.last4) === i &&
      a.findIndex((t) => t.id === v.id) === i
  );
  const [customerId, setCustomerId] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [showAddCard, setShowAddCard] = useState(false);
  const location = useLocation();
  const [selectPaymentMethod, setSelectPaymentMethod] = useState(null);
  const [cardIcon, setCardIcon] = useState(null);

  const [message, setMessage] = useState(null);
  const [donationError, setDonationError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isCardComplete, setIsCardComplete] = useState(false);

  const [accessToken, setAccessToken] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [amount, setAmount] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [succeeded, setSucceeded] = useState(false);

  const [currency, setCurrency] = useState(null);
  const [receiver, setReceiver] = useState(null);
  const [language, setLanguage] = useState("en");
  const [openPaymentMethods, setOpenPaymentMethods] = React.useState(false);
  const [openCurrentPaymentMethods, setOpenCurrentPaymentMethods] =
    React.useState(false);
  const [isCardNumberValid, setIsCardNumberValid] = useState(false);
  const [isCardExpiryValid, setIsCardExpiryValid] = useState(false);
  const [isCardCvcValid, setIsCardCvcValid] = useState(false);

  const [openAddCard, setOpenAddCard] = React.useState(false);

  const { t, i18n } = useTranslation();

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: "18px",
        color: "#424770",
        backgroundColor: "#FAF2F0",

        fontSmoothing: "antialiased",

        "::placeholder": {
          color: "#aab7c4",

          fontSize: "12px",

          fontWeight: "500",
          backgroundColor: "#FAF2F0",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
  };

  const currencyToSymbolMap = {
    EUR: "€",
    DKK: "kr",
  };

  const defaultAmounts = [3, 10, 40, 100, 250];
  const danishAmounts = [25, 75, 300, 750, 2000];

  const arrayTypes = [
    {
      value: "world_wildlife_fund",
      label: "World Wildlife Fund",
      icon: wwfIcon,
      description:
        "The Red Cross is always there. We save lives every day, and we equip people with the tools they need to get through crises and disasters.We are the world's largest humanitarian organisationwith about 12 million volunteers throughout the world. We are a movement for humanitarianism in the world, in the local community and where you live.In Denmark, the Danish Red Cross is there for vulnerable children and adults of all ages. We forge communities and help vulnerable people in society lead a better life. In crisis situations we are part of the national disaster-response in Denmark. Internationally, the Danish Red Cross is present in conflicts and emergencies. We help people on both sides of a conflict and in the remotest corners of the world, regardless of their nationality, religion or political views. Thousands of people in Denmark support the Danish Red Cross every year by donating money to the work we do. The support is paramount to our work in Denmark and in the crisis areas of the world.By supporting the Danish Red Cross, you are an important part of getting the help to those who need it the most. With your support, the Red Cross can continue to be there.",
    },
    {
      value: "red_cross",
      label: "Danish Red Cross",
      icon: redCrossIcon,
      description: "Hell",
    },
    {
      value: "sos_children_villages",
      label: "Sos Children Villages",
      icon: sosIcon,
      description:
        "SOS Children's Villages is an independent, non-governmental international development organization which has been working to meet the needs and protect the interests and rights of children since 1949.",
    },
  ];

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const querycurrency = query.get("currency");
    const queryreceiver = query.get("receiver");
    const queryLanguage = query.get("language") || "en";

    let token = query.get("Authorization");
    setAccessToken(token);
    setCurrency(querycurrency);
    setReceiver(queryreceiver);
    setLanguage(queryLanguage);

    i18n.changeLanguage(queryLanguage);

    if (querycurrency == "DKK") {
      preDefindAmounts = danishAmounts.map(
        (amount) =>
          ({} = {
            value: amount,
            label: amount + " " + currencyToSymbolMap[querycurrency],
          })
      );
    } else {
      preDefindAmounts = defaultAmounts.map(
        (amount) =>
          ({} = {
            value: amount,
            label: amount + " " + currencyToSymbolMap[querycurrency],
          })
      );
    }
    setAmount(preDefindAmounts[0].value);
    setOrganization(arrayTypes[0].value);
  }, []);

  useEffect(() => {
    if (!accessToken) return;
    axios
      .get(baseUrl + "/payment-methods", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        console.log("response", response.data);

        setPaymentMethods((prevMethods) => {
          const uniqueMethods = response.data.paymentMethods.filter(
            (method) =>
              !prevMethods.some((prevMethod) => prevMethod.id === method.id)
          );
          return [...uniqueMethods, ...prevMethods];
        });
        setCustomerId(response.data.stripeId);

        if (response.data.paymentMethods.length === 0) {
          handleShowAddCard();
        }

        if (!selectedPaymentMethod && response.data.paymentMethods.length > 0) {
          setSelectedPaymentMethod(response.data.paymentMethods[0].id);
        }
      })
      .catch((error) => console.error("Error fetching payment methods", error));
  }, [accessToken]);

  const handleShowAddCard = () => {
    setShowAddCard(true);
  };

  const handleDonate = async () => {
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    setDonationError(null);
    let paymentMethodId = selectedPaymentMethod;

    if (openAddCard || !selectedPaymentMethod) {
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      // Create the payment method
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
      });

      // await stripe.

      if (error) {
        console.log("[error]", error);
        setDonationError(t("paymentError"));
        setIsLoading(false);
        return;
      }

      console.log("[PaymentMethod]", paymentMethod);
      paymentMethodId = paymentMethod.id;
    }

    // Proceed with donation.
    if (!paymentMethodId) {
      console.error("No payment method selected");
      setMessage("No payment method selected!");
      setIsLoading(false);
      return;
    }

    axios
      .post(
        baseUrl + "invitations",
        {
          amount,
          organization,
          currency,
          receiver,
          paymentMethodId,
          attach: openAddCard || !selectedPaymentMethod,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        console.log("response", response.data);
        setIsLoading(false);

        if (response.data && response.data.id) {
          // Payment successful
          setSucceeded(true);
        } else {
          // Payment not successful, show error message
          setDonationError(t("paymentError"));
        }
      })
      .catch((error) => {
        setDonationError(t("paymentError"));
        console.error("Error submitting donation", error);
        setIsLoading(false);
      });
  };

  const handlePaymentMethod = (paymentMethodId) => {
    setSelectPaymentMethod(paymentMethodId);
  };

  const handleCardNumberChange = async (event) => {
    setIsCardNumberValid(event.complete && !event.error);

    console.log(event);
    // Extract the first two digits
    // const firstTwoDigits = cardNumber.slice(0, 2);

    if (event.brand === "visa") {
      setCardIcon(Visa);
    } else if (event.brand === "mastercard") {
      setCardIcon(MasterCard);
    } else if (event.brand === "amex") {
      setCardIcon(Amex);
    } else {
      setCardIcon(CardExmaple);
    }
  };

  const handleCardExpiryChange = async (event) => {
    setIsCardExpiryValid(event.complete && !event.error);
  };

  const handleCardCvcChange = async (event) => {
    setIsCardCvcValid(event.complete && !event.error);
  };

  return (
    <div style={succeeded ? mainContainerSuccess : mainContainer}>
      {succeeded ? (
        <SuccessComponent></SuccessComponent>
      ) : (
        <>
          {openPaymentMethods ? (
            <div>
              {openAddCard ? (
                <div>
                  <div onClick={() => setOpenAddCard(false)}>
                    <img
                      src={BackArrow}
                      alt="BackArrow"
                      style={{
                        marginRight: 8,
                        width: "20px",
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <div className="title">{t("cardTitle")}</div>
                </div>
              ) : (
                <div>
                  <div onClick={() => setOpenPaymentMethods(false)}>
                    <img
                      src={BackArrow}
                      alt="BackArrow"
                      style={{
                        marginRight: 8,
                        width: "20px",
                        marginBottom: 10,
                      }}
                    />
                  </div>
                  <div className="title">{t("cardTitle")}</div>
                </div>
              )}
              {(!selectedPaymentMethod || openAddCard) && (
                <div>
                  <div style={{ marginTop: 16 }}>
                    <span className="cardText">{t("cardNumber")}</span>
                    <div
                      style={{
                        padding: "12px",
                        width: "327px",
                        borderRadius: "8px",
                        background: "#FAF2F0",
                        marginTop: "4px",
                        flexDirection: "row",
                        display: "flex",
                      }}
                    >
                      {cardIcon && (
                        <div
                          style={{
                            height: "25px",
                            width: "25px",
                            marginRight: 8,
                          }}
                        >
                          <img src={cardIcon} alt="Card Icon" />
                        </div>
                      )}
                      <div style={{ flex: 1 }}>
                        <CardNumberElement
                          options={CARD_ELEMENT_OPTIONS}
                          onChange={handleCardNumberChange}
                        />
                      </div>
                    </div>
                  </div>
                  <span className="cardText"> {t("cardExpire")}</span>
                  <div
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      width: "140px",
                      background: "#FAF2F0",
                      marginTop: "4px",
                    }}
                  >
                    <CardExpiryElement
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={handleCardExpiryChange}
                    />
                  </div>
                  <span className="cardText"> {t("cardCVC")}</span>
                  <div
                    style={{
                      padding: "12px",
                      borderRadius: "8px",
                      background: "#FAF2F0",
                      width: "120px",
                      marginTop: "4px",
                    }}
                  >
                    <CardCvcElement
                      options={CARD_ELEMENT_OPTIONS}
                      onChange={handleCardCvcChange}
                    />
                  </div>
                </div>
              )}
              {filteredCards.map((paymentMethod) => (
                <>
                  {!openAddCard && (
                    <button
                      onClick={() => handlePaymentMethod(paymentMethod.id)}
                      className="container"
                      key={paymentMethod.id}
                    >
                      <div>
                        {paymentMethod.brand === "visa" ? (
                          <img
                            src={Visa}
                            alt="Visa"
                            style={{ marginRight: 8, width: "20px" }}
                          />
                        ) : (
                          <img
                            src={MasterCard}
                            alt="MasterCard"
                            style={{ marginRight: 8, width: "20px" }}
                          />
                        )}
                        {paymentMethod.last4}
                      </div>
                      {selectPaymentMethod === paymentMethod.id && (
                        <img src={Check} alt="Check" />
                      )}
                    </button>
                  )}
                  {!openAddCard && (
                    <>
                      {openCurrentPaymentMethods && (
                        <div>
                          <div className="container2" key={paymentMethod.id}>
                            <div>
                              {paymentMethod.brand === "visa" ? (
                                <img
                                  src={Visa}
                                  alt="Visa"
                                  style={{ marginRight: 8, width: "20px" }}
                                />
                              ) : (
                                <img
                                  src={MasterCard}
                                  alt="MasterCard"
                                  style={{ marginRight: 8, width: "20px" }}
                                />
                              )}
                              {paymentMethod.last4}
                            </div>
                            <div>
                              <button className="delete">
                                <span>Delete</span>
                              </button>
                            </div>
                          </div>
                          <div className="container2">
                            <div
                              style={{
                                flexDirection: "row",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <img
                                src={CardExmaple}
                                alt="CardExample"
                                style={{ marginRight: 8, width: "20px" }}
                              />
                              <span className="xtext">XXXX</span>
                            </div>
                            <button
                              onClick={() => {
                                setOpenAddCard(true);
                              }}
                              className="delete"
                            >
                              <span className="addNew">Add new</span>
                            </button>
                          </div>{" "}
                        </div>
                      )}
                    </>
                  )}
                </>
              ))}
              {!openAddCard && (
                <div>
                  {!showAddCard && (
                    <button
                      className="donate"
                      disabled={!selectPaymentMethod}
                      onClick={handleDonate}
                    >
                      {isLoading ? (
                        <span className="loader"></span>
                      ) : (
                        <span className="donate">{t("button2")}</span>
                      )}
                    </button>
                  )}
                  {!showAddCard ? (
                    <button
                      onClick={() => setOpenAddCard(true)}
                      className="editBtn"
                    >
                      {" "}
                      {t("button4")}
                    </button>
                  ) : (
                    <button
                      onClick={handleDonate}
                      disabled={
                        isLoading ||
                        !(
                          isCardNumberValid &&
                          isCardCvcValid &&
                          isCardExpiryValid
                        )
                      }
                    >
                      {isLoading ? (
                        <span className="loader"></span>
                      ) : (
                        <span className="donate">{t("button2")}</span>
                      )}
                    </button>
                  )}
                </div>
              )}
              {openAddCard && (
                <button
                  onClick={handleDonate}
                  disabled={
                    isLoading ||
                    !(isCardNumberValid && isCardCvcValid && isCardExpiryValid)
                  }
                >
                  {isLoading ? (
                    <span className="loader"></span>
                  ) : (
                    <span className="donate">{t("button2")}</span>
                  )}
                </button>
              )}
              {donationError && <div className="message">{donationError}</div>}
            </div>
          ) : (
            <PreDonateComponent
              preDefindAmounts={preDefindAmounts}
              setOpenPaymentMethods={setOpenPaymentMethods}
              message={message}
              donationError={donationError}
              onOrganizationChange={(newOrganization) => {
                setOrganization(newOrganization);
              }}
              onAmountChange={(newAmount) => {
                setAmount(newAmount);
              }}
            ></PreDonateComponent>
          )}
        </>
      )}
    </div>
  );
};

const AddPaymentMethodScreen = () => {
  const [clientSecret, setClientSecret] = useState("");

  const options = {
    clientSecret,
  };

  return (
    <Elements stripe={stripePromise}>
      <CardForm />
    </Elements>
  );
};

export default AddPaymentMethodScreen;

const mainContainer = {
  marginTop: 40,
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  paddingLeft: 20,
  paddingRight: 20,
};

const mainContainerSuccess = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100vh",
};
