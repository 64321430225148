import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import wwfIcon from "../assets/WWF_logo 1.svg";
import redCrossIcon from "../assets/redcross.svg";
import redCrossIcon2 from "../assets/red-cross-logo.svg";

import sosIcon from "../assets/sos.svg";
import dfgLogo from "../assets/Group 80.svg";
import remove from "../assets/remove.svg";

const PreDonateComponent = (props) => {
  const {
    preDefindAmounts,
    setOpenPaymentMethods,
    onOrganizationChange,
    onAmountChange,
  } = props;
  const [organization, setOrganization] = useState(null);
  const [name, setName] = useState(null);
  const [amount, setAmount] = useState(null);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const arrayTypes = [
    {
      value: "world_wildlife_fund",
      label: t("value1"),
      icon: wwfIcon,
      icon2: wwfIcon,
      description: t("text1"),
      title: t("title"),
    },
    {
      value: "red_cross",
      label: t("value2"),
      icon: redCrossIcon,
      icon2: redCrossIcon2,
      // i want icon 2 to be optional

      description: t("text2"),
      title: t("title1"),
    },
    {
      value: "sos_children_villages",
      label: t("value3"),
      icon: sosIcon,
      icon2: sosIcon,
      description: t("text3"),
      title: t("title2"),
    },
  ];

  // Add new state for checkbox state
  const [checkBoxState, setCheckBoxState] = useState(
    Array(arrayTypes.length).fill(false)
  );

  const [showPopup, setShowPopup] = useState(false);
  const [selectedOrgContent, setSelectedOrgContent] = useState("");
  const [selectedOrgTitle, setSelectedOrgTitle] = useState("");
  const [icon, setIcon] = useState("");

  const handleReadMoreClick = (content, title, icon) => {
    setSelectedOrgContent(content);
    setSelectedOrgTitle(title);
    setShowPopup(true);
    setIcon(icon);
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const queryOrganization = query.get("organization");
    const personName = query.get("firstName") || "Person";
    if (personName) {
      setName(personName);
    }
    if (queryOrganization) {
      setOrganization(queryOrganization);
    }
  }, [location.search]);

  const handleOrganizationChange = (selectedOption) => {
    setOrganization(selectedOption.value);
    onOrganizationChange(selectedOption.value);
  };

  const handleAmountChange = (selectedOption) => {
    setAmount(selectedOption.value);
    onAmountChange(selectedOption.value);
  };

  const isSelectPaymentDisabled = () => {
    return !organization || !amount;
  };

  const handleProceedToPayment = () => {
    setOpenPaymentMethods(true);
  };

  // Add new method to handle checkbox click
  const handleCheckboxClick = (index) => {
    const newCheckBoxState = [...checkBoxState];
    newCheckBoxState[index] = !newCheckBoxState[index];
    setCheckBoxState(newCheckBoxState);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="title">{t("youAreDonating")}</div>
      {showPopup && (
        <div className="blur-background" onClick={closePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <div className="title-x">
              <div className="title-popup">{selectedOrgTitle}</div>
              <div className="close-btn" onClick={closePopup}>
                <img src={remove} alt="mainDfg" style={{}} />
              </div>
            </div>
            <div className="logos">
              <img
                src={dfgLogo}
                alt="mainDfg"
                style={{
                  marginRight: 8,
                  height: 80,
                  width: 80,
                  marginBottom: 10,
                }}
              />
              <img
                src={icon}
                alt="mainDfg"
                style={{
                  marginRight: 8,
                  marginBottom: 10,
                }}
              />
            </div>
            <p className="text-organ">{selectedOrgContent}</p>
          </div>
        </div>
      )}

      <div className="organizationsWrapper">
        {arrayTypes.map((type, index) => (
          <div
            className={
              type.value === organization
                ? "cardElementSelected"
                : "cardElement"
            }
            key={type.value}
            onClick={() => {
              handleOrganizationChange(type);
            }}
          >
            <div>
              <div className="checkboxWrapper">
                <div
                  className={
                    type.value === organization
                      ? "checkbox checked"
                      : "checkbox"
                  }
                  onClick={(event) => {
                    event.stopPropagation();
                    handleCheckboxClick(index);
                  }}
                />
              </div>
              <div className="cardElementContainer" key={type.value}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 12,
                    flexDirection: "column",
                  }}
                >
                  <img
                    src={type.icon}
                    alt={type.label}
                    style={{
                      marginRight: 8,
                      height: 50,
                      marginBottom: 10,
                    }}
                  />
                  {type.label}
                </div>
                <span
                  onClick={() =>
                    handleReadMoreClick(
                      type.description,
                      type.title,
                      type.icon2
                    )
                  }
                  className="read-more"
                >
                  {t("readMore")}
                </span>
              </div>
            </div>
          </div>
        ))}
      </div>
      {preDefindAmounts.length > 0 && (
        <>
          <div className="title2">{t("amountOf")}</div>
          <div className="amountBox">
            {preDefindAmounts.map((type) => (
              <div
                className={
                  type.value === amount ? "amount-box" : "amount-box-selected"
                }
                key={type.value}
                onClick={() => {
                  handleAmountChange(type);
                }}
              >
                <div key={type.value}>
                  <span>{type.label}</span>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div className="btnDiv">
        <span className="text">{t("text")}</span>
        <button
          disabled={isSelectPaymentDisabled()}
          className="donate1"
          onClick={() => handleProceedToPayment()}
        >
          {isLoading ? (
            <span className="loader"></span>
          ) : (
            <span className="donate1"> {t("button1")}</span>
          )}
        </button>
      </div>
    </div>
  );
};

export default PreDonateComponent;
