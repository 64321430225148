import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationEN from './translations/en.json'
import translationDA from './translations/da.json'

i18n
   .use(initReactI18next)
   .init({
      compatibilityJSON: 'v3',
      fallbackLng: 'en',
      lng: 'en',
      debug: false,
      interpolation: {
         escapeValue: false,
      },
      resources: {
         en: {
            translation: translationEN,
         },
         da: {
            translation: translationDA,
         }
      },
   })

export default i18n
