import Success from "../assets/success.svg";

const SuccessComponent = () => {
    return (
        <div className="success">
            <img
                src={Success}
                alt="Success"
                style={{
                    marginRight: 8,
                    width: "40px",
                    height: "40px",
                    marginBottom: 10,
                }}
            />
            <text className="successText">Payment Successful!</text>
            <p>Thank you for your donation!</p>
        </div>
    )
}

export default SuccessComponent;